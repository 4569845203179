import React, { useEffect, useState } from 'react';
import Table from '@components/table';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useConsumers } from '@hooks/api/useConsumers';
import { EUserStatus, TConsumerLite, TUser } from '@xeppt/xeppt-sdk/types';
import { useDebounceValue } from 'usehooks-ts';
import Status from '@components/common/status';
import { getConsumerStatus, getConsumerStatusColor } from '@utils/index';
import { apiAdminService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { consumerStatuses } from '@const/index';
import Dropdown from '@components/common/dropdown';

const ConsumersLayout = () => {
    const {
        consumers,
        consumersFilter,
        handleChangeConsumersFilter,
        refetchConsumers,
        consumersData,
        handleDeleteUser
    } = useConsumers({ isInitialConsumers: false });
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);

    const handleUpdateConsumerStatus = (id: string, status: EConsumerStatus) => {
        apiAdminService
            .updateConsumerStatus(id, status)
            .then(() => {
                requestSuccessLocale('update_consumer');
                handleChangeConsumersFilter(consumersFilter);
            })
            .catch(requestErrorLocale);
    };

    const columns = [
        {
            label: 'Name',
            key: 'name',
            render: (_: string, data: TConsumerLite) => `${data.firstName} ${data.lastName}`
        },
        {
            label: 'Email',
            key: 'email'
        },
        {
            label: 'Phone',
            key: 'phone',
            render: (_: string, data: TConsumerLite) => `+${data.phoneCode} ${data.phoneNumber}`
        },
        {
            label: 'Verification',
            key: 'status',
            isSingleFilter: true,
            render: (value: EConsumerStatus) =>
                value !== EConsumerStatus.UNVERIFIED && value !== EConsumerStatus.UNCONFIRMED
                    ? 'Verified'
                    : 'Not verified',
            filterOptions: [
                {
                    label: 'Verified',
                    value: EConsumerStatus.ACTIVE
                },
                {
                    label: 'Not Verified',
                    value: EConsumerStatus.UNVERIFIED
                }
            ]
        },
        {
            label: 'Status',
            key: 'status',
            isSingleFilter: true,
            isOverflow: false,
            render: (val: EConsumerStatus, data: TConsumerLite) => (
                <Status
                    variant={getConsumerStatusColor(val)}
                    isUpdate
                    onUpdate={(val) => handleUpdateConsumerStatus(data.id, val)}
                    updateOptions={consumerStatuses}>
                    {getConsumerStatus(val)}
                </Status>
            ),
            filterOptions: consumerStatuses
        },
        {
            label: 'Date created',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('MM.DD.YYYY HH:mm:ss')
        },
        {
            label: 'Profile',
            key: 'id',
            render: (value: string, user: TUser) => (
                <Dropdown
                    items={[
                        {
                            label: 'View profile',
                            onClick: () => navigate(`${routes.consumers}/${value}`)
                        },
                        ...((user.status === EUserStatus.UNVERIFIED && [
                            {
                                label: 'Delete',
                                onClick: () => handleDeleteUser(value)
                            }
                        ]) ||
                            [])
                    ]}
                />
            )
        }
    ];

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            handleChangeConsumersFilter({ search });
        } else {
            handleChangeConsumersFilter({ search: undefined });
        }
    }, [requestSearch]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Consumers</Typography>
                    <Typography>{consumersData?.total} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <Input
                        className={styles.search}
                        leftIcon="search"
                        value={search}
                        onChange={(val) => setSearch(val)}
                        placeholder="Search by consumer name, email or ID"
                    />
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={consumers}
                onFilterChange={({ status }) => {
                    if (status && status?.length > 0) {
                        handleChangeConsumersFilter({ status });
                    } else {
                        handleChangeConsumersFilter({ status: undefined });
                    }
                }}
                totalRows={consumersData?.total || 0}
                onLoadData={refetchConsumers}
                filter={{
                    status: consumersFilter?.status ? consumersFilter?.status : []
                }}
                onSortChange={() => {
                    handleChangeConsumersFilter({
                        order: consumersFilter.order === 'DESC' ? 'ASC' : 'DESC'
                    });
                }}
                sort={{ key: 'createdAt', value: consumersFilter.order }}
                emptyDescription="Consumers list is empty"
            />
        </div>
    );
};

export default ConsumersLayout;
