import React, { useEffect, useState } from 'react';
import Table, { TFilter } from '@components/table';
import styles from './styles.module.scss';
import Status from '@components/common/status';
import PriceStatus from '@components/common/price_status';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import Range from '@components/range';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types/transaction';
import moment from 'moment/moment';
import {
    downloadFileFromBlob,
    formatDataToXlsx,
    getTransactionAction,
    getTransactionMethod,
    getTransactionStatus,
    getTransactionStatusColor
} from '@utils/index';
import { useTransactions } from '@hooks/api/useTransactions';
import { useDebounceValue } from 'usehooks-ts';
import {
    moneyOutActions,
    transactionsMethods,
    transactionsStatuses,
    transactionsType
} from '@const/index';
import { routes } from '@const/routes';
import Link from '@components/common/link';
import DatePicker from '@components/date_picker';
import Dropdown from '@components/common/dropdown';

const TransactionsLayout = () => {
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isTransactionDrawerOpen, setIsTransactionDrawerOpen] = useState(false);
    const [dateFilter, setDateFilter] = useState<string>();

    const {
        filter,
        onChangeFilter,
        transactions,
        transactionsData,
        refetchTransactions,
        transaction,
        getSingleTransaction,
        handleRemoveTransactionData
    } = useTransactions({ isInitialTransactions: false });

    const [search, setSearch] = useState('');
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);

    const columns = [
        {
            label: 'Date and Time',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('MM.DD.YYYY HH:mm')
        },
        {
            label: 'Transaction ID',
            key: 'id'
        },
        {
            label: 'Transaction Method',
            key: 'method',
            render: (value: ETransactionMethod) => getTransactionMethod(value)
        },
        {
            label: 'Consumer Name',
            key: 'consumerName',
            render: (value: string, data: TTransactionLite) => (
                <Link to={`${routes.consumers}/${data.consumerId}`}>{value}</Link>
            )
        },
        {
            label: 'Action',
            key: 'action',
            render: (value: ETransactionAction) => getTransactionAction(value)
        },
        {
            label: 'Status',
            key: 'status',
            render: (value: ETransactionStatus) => (
                <Status variant={getTransactionStatusColor(value)}>
                    {getTransactionStatus(value)}
                </Status>
            )
        },
        { label: 'Currency', key: 'currency' },
        {
            label: 'Amount',
            key: 'amount',
            render: (value: number, data: TTransactionLite) => (
                <PriceStatus
                    isNegative={moneyOutActions.includes(data.action)}
                    amount={value}
                    currency="$"
                />
            )
        },
        {
            label: 'Actions',
            key: 'id',
            render: (value: string) => (
                <Dropdown
                    items={[
                        {
                            label: 'Details',
                            onClick: () => {
                                setIsTransactionDrawerOpen(true);
                                getSingleTransaction(value);
                            }
                        }
                    ]}
                />
            )
        }
    ];

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            onChangeFilter({ search });
        } else {
            onChangeFilter({ search: undefined });
        }
    }, [requestSearch]);

    const handleChangeDateType = (value: string) => {
        if (dateFilter === value) {
            setDateFilter('');
            onChangeFilter({
                dateFrom: undefined,
                dateTo: undefined
            });
        } else {
            setDateFilter(value);
        }
        if (value === 'week') {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            onChangeFilter({
                dateFrom: date,
                dateTo: new Date()
            });
        } else if (value === 'month') {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            onChangeFilter({
                dateFrom: date,
                dateTo: new Date()
            });
        } else if (value === 'year') {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            onChangeFilter({
                dateFrom: date,
                dateTo: new Date()
            });
        }
    };

    const handleDownloadTransaction = () => {
        const formatTransaction = transactions.map((item) => ({
            'Transaction ID': item.id,
            Name: item.consumerName,
            Action: getTransactionAction(item.action),
            Amount: item.amount,
            Currency: item.currency,
            'Date ': moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss'),
            Method: getTransactionMethod(transaction?.method),
            Status: getTransactionStatus(transaction?.status),
            'Is Automatic': item.automatic ? 'Yes' : 'No',
            'Consumer Name': item.consumerName,
            'Consumer Email': item.consumerEmail
        }));
        downloadFileFromBlob(formatDataToXlsx(formatTransaction), 'all-transactions.csv');
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Transactions</Typography>
                    <Typography>{transactionsData?.total} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <RangeDatePicker
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            })
                        }
                    />
                    <Button
                        size="medium"
                        variant="outlined"
                        leftIcon="download"
                        onClick={handleDownloadTransaction}>
                        Download
                    </Button>
                    <Button
                        size="medium"
                        variant="primary"
                        leftIcon="filter_bars"
                        onClick={() => setIsFilterDrawerOpen(true)}>
                        Filter
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                rows={transactions}
                sort={{ key: 'createdAt', value: filter.order }}
                onSortChange={() => {
                    onChangeFilter({
                        order: filter.order === 'DESC' ? 'ASC' : 'DESC'
                    });
                }}
                filter={filter as TFilter}
                onLoadData={refetchTransactions}
                totalRows={transactionsData?.total || 0}
                onFilterChange={(val) => onChangeFilter(val)}
                emptyDescription="Transactions list is empty"
            />
            <Drawer
                className={styles.drawer}
                isOpen={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}>
                <Input
                    full
                    leftIcon="search"
                    value={search}
                    onChange={(val) => setSearch(val)}
                    placeholder="Search by transaction ID"
                />
                <Accordion label="Date and Time" isDefaultOpened>
                    <div className={styles.date_accordion}>
                        <Button
                            variant={dateFilter === 'week' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('week')}>
                            Last week
                        </Button>
                        <Button
                            variant={dateFilter === 'month' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('month')}>
                            Last month
                        </Button>
                        <Button
                            variant={dateFilter === 'year' ? 'primary' : 'outlined'}
                            size="small"
                            onClick={() => handleChangeDateType('year')}>
                            Last year
                        </Button>
                    </div>
                    <RangeDatePicker
                        full
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            })
                        }
                    />
                </Accordion>
                <Accordion label="Transaction Method" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.method || []}
                        onChange={(val) => {
                            onChangeFilter({ method: val as ETransactionMethod[] });
                        }}
                        items={transactionsMethods}
                    />
                </Accordion>
                <Accordion label="Action" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.action || []}
                        onChange={(val) => {
                            onChangeFilter({ action: val as ETransactionAction[] });
                        }}
                        items={transactionsType}
                    />
                </Accordion>
                <Accordion label="Status" isDefaultOpened>
                    <MultiSelect
                        full
                        values={filter.status || []}
                        onChange={(val) => {
                            onChangeFilter({ status: val as ETransactionStatus[] });
                        }}
                        items={transactionsStatuses}
                    />
                </Accordion>
                <Accordion label="Amount" isDefaultOpened>
                    <Range
                        min={0}
                        max={1000}
                        step={1}
                        values={[filter.amountFrom || 0, filter.amountTo || 1000]}
                        onChange={(val) => {
                            onChangeFilter({
                                amountFrom: val?.[0],
                                amountTo: val?.[1]
                            });
                        }}
                    />
                </Accordion>
            </Drawer>
            <Drawer
                className={styles.transaction_drawer}
                isOpen={isTransactionDrawerOpen && !!transaction}
                onClose={() => {
                    setIsTransactionDrawerOpen(false);
                    handleRemoveTransactionData();
                }}>
                <Typography className={styles.title} variant="h5">
                    Transaction details
                </Typography>
                <Input full value={transaction?.id} label="Transaction ID" readOnly />
                <div className={styles.row}>
                    <Input full value={transaction?.consumerId} label="Consumer ID" readOnly />
                    <Input full value={transaction?.consumerName} label="Consumer Name" readOnly />
                </div>
                <div className={styles.row}>
                    <Input
                        full
                        value={getTransactionMethod(transaction?.method)}
                        label="Method"
                        readOnly
                    />
                    <Input
                        full
                        value={getTransactionAction(transaction?.action)}
                        label="Action"
                        readOnly
                    />
                    <Input
                        full
                        value={getTransactionStatus(transaction?.status)}
                        label="Status"
                        readOnly
                    />
                </div>
                <div className={styles.row}>
                    <Input full value={transaction?.currency} label="Currency" readOnly />
                    <Input full value={transaction?.amount} label="Amount" readOnly />
                </div>
                <Input
                    full
                    value={transaction?.automatic ? 'Yes' : 'No'}
                    label="Is bill payment"
                    readOnly
                />
                <div className={styles.row}>
                    <Input
                        full
                        //@ts-ignore
                        value={transaction?.details?.message || '-'}
                        label="Message"
                        readOnly
                    />
                    <Input full value={transaction?.destination} label="Destination" readOnly />
                </div>
                <div className={styles.row}>
                    <Input full value={transaction?.source} label="Source" readOnly />
                    <DatePicker
                        label="Created at"
                        full
                        //@ts-ignore
                        value={transaction?.createdAt as string}
                        readOnly
                    />
                </div>
            </Drawer>
        </div>
    );
};

export default TransactionsLayout;
